import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import codeGenerator from "@/services/general/codeGenerator.js";

const UserService = {
  createUser(userData, selectedState, removedFoldersId, dureeAcces) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("RemoveFolders :", removedFoldersId);
        var group = Parse.User.current().get("group");
        var userTherapeute = Parse.User.current();
        var username = userData.lastname + userData.firstname;
        var mail = userData.mail;
        var dateNow = new Date();
        var user = new Parse.User();
        var relatedFoldersArray = [];
        console.log(
          "flashQuestionnaire",
          userData.flashQuestionnaire,
          "flashQuestionnaire2",
          userData.flashQuestionnaire2
        );
        user.set("firstname", userData.firstname);
        user.set("lastname", userData.lastname);
        let questionnaireRelation = user.relation("QuestionnaireBeginIdList");
        if (userData.flashQuestionnaire || userData.flashQuestionnaire2) {
          // Itérer sur chaque identifiant de questionnaire dans le tableau
          for (let idQuestionnaire of userData.BeginQuestionnaireIds) {
            // Créer une requête pour récupérer le questionnaire correspondant à l'identifiant
            let QuestionnaireQuery = new Parse.Query("questionnaire");
            QuestionnaireQuery.equalTo("objectId", idQuestionnaire);

            // Exécuter la requête pour obtenir le premier résultat
            const questionnaire = await QuestionnaireQuery.first();

            // Si un questionnaire correspondant est trouvé, le lier à l'utilisateur
            if (questionnaire) {
              user.set("QuestionnaireBegin", true); // Assumer que le questionnaire doit commencer
              user.set("QuestionnaireBeginId", questionnaire);
              questionnaireRelation.add(questionnaire);
            }
          }
        }

        if (mail) {
          user.set("email", userData.mail);
          user.set("username", userData.mail);
        } else {
          user.set("email", userData.lastname + "@" + userData.firstname);
          user.set("username", username);
        }
        const passwordGenerate = codeGenerator("xxxxxxxx");

        user.set("password", passwordGenerate);
        user.set("proposition_affiliation", false);
        user.set("withdrawal_credit_date", dateNow);
        user.set("cgu", false);
        user.set("therapeute", false);
        user.set("group", group);
        user.set("type_pratique", selectedState);

        if (selectedState === "licence") {
          user.set("date_debut_licence", dateNow);
          //mettre en number la durée d'accès
          dureeAcces = parseInt(dureeAcces);
          user.set("duree_acces", dureeAcces);
          user.set("acces_donnees_therapeute", false);
          user.set("newsletters", false);
          user.set("acces_donnees_symbiocenter");
          var dateFinLicence = new Date();
          var dureeEnJours = dureeAcces * 30;
          dateFinLicence.setDate(dateFinLicence.getDate() + dureeEnJours);
          user.set("fin_acces_plateforme", dateFinLicence);

          await Parse.Cloud.run("pratiqueSousLicence", {
            destinataire: userData.mail,
            utilisateur: userData.firstname + " " + userData.lastname,
            password: passwordGenerate,
            therapeute:
              userTherapeute.attributes.firstname +
              " " +
              userTherapeute.attributes.lastname,
            dureeAcces: dureeAcces,
          });
        }

        var linkRelation = user.relation("link");
        var queryAvailableFolders = new Parse.Query("AvailableFolders");

        const results = await queryAvailableFolders.find();
        if (results.length > 0) {
          var availableFolders = results;

          await Promise.all(
            availableFolders.map(async (folder) => {
              var foldersRelation = folder.relation("folders");
              var foldersQuery = foldersRelation.query();
              const relatedFolders = await foldersQuery.find();
              relatedFoldersArray.push(...relatedFolders);
            })
          );

          //  inclure uniquement les folders ayant leur id dans le tableau removedFoldersId
          // if (selectedState === "licence") {
          relatedFoldersArray = relatedFoldersArray.filter((folder) =>
            removedFoldersId.includes(folder.id)
          );
          // }
          await Promise.all(
            relatedFoldersArray.map(async (folder) => {
              const linkQuery = new Parse.Query("link");
              linkQuery.equalTo("folder", folder);
              const link = await linkQuery.first();
              if (link) {
                linkRelation.add(link);
              }
            })
          );

          user
            .save()
            .then((user) => {
              //console.log("Utilisateur créé avec succès", user);
              resolve(user); // Renvoie l'utilisateur créé
            })
            .catch((error) => {
              console.error(
                "Erreur lors de la création de l'utilisateur",
                error
              );
              reject(error); // Renvoie l'erreur en cas d'échec
            });
        } else {
          reject("Aucun dossier disponible"); // Renvoie un message d'erreur en cas de dossier indisponible
        }
      } catch (error) {
        console.error("Erreur lors de la création de l'utilisateur", error);
        reject(error); // Renvoie l'erreur en cas d'erreur inattendue
      }
    });
  },
  createTherapeute(user, progressCallback, isStagiaire) {
    ParseConfig();
    // //console.log("user", user);
    return new Promise(async (resolve, reject) => {
      try {
        // //console.log("user", user);
        var relatedFoldersArray = [];
        var NewUser = new Parse.User();
        NewUser.set("firstname", user.prenom);
        NewUser.set("lastname", user.nom);
        NewUser.set("compte_actif", true);
        NewUser.set("cgu", true);
        NewUser.set("therapeute", true);
        NewUser.set("telephone_pro", user.telephone_pro);
        NewUser.set("username", user.mail);
        NewUser.set("profession", user.profession);
        NewUser.set("email", user.mail);
        NewUser.set("new_version_enabled",true);
        progressCallback(10);
        if (user.password === user.confirmPassword) {
          NewUser.set("password", user.password);
        } else {
          //console.log("Les mots de passe ne correspondent pas");
        }
        progressCallback(20);
        var linkRelation = NewUser.relation("link");
        var queryAvailableFolders = new Parse.Query("AvailableFolders");
        progressCallback(30);
        const results = await queryAvailableFolders.find();
        if (results.length > 0) {
          var availableFolders = results;
          progressCallback(40);
          await Promise.all(
            availableFolders.map(async (folder) => {
              var foldersRelation = folder.relation("folders");
              var foldersQuery = foldersRelation.query();
              const relatedFolders = await foldersQuery.find();
              relatedFoldersArray.push(...relatedFolders);
            })
          );
          progressCallback(50);
          await Promise.all(
            relatedFoldersArray.map(async (folder) => {
              const linkQuery = new Parse.Query("link");
              linkQuery.equalTo("folder", folder);
              const link = await linkQuery.first();
              if (link) {
                linkRelation.add(link);
              }
            })
          );
          progressCallback(60);
        }

        var UserGroup = new Parse.Object("user_group");

        // var base64 = basePart1 + basePart2 + basePart3;
        // console.log('Base64:', base64);
        // var parseFile = new Parse.File(
        //   "Logos_Symbiofi_SymbioCenter_PRO_vectoriser.png",
        //   { base64: base64 }
        // );
        progressCallback(70);
        // const fileSave = await parseFile.save();
        // UserGroup.set("logo", fileSave);

        var domaine = new Parse.Query("domaine");
        domaine.equalTo("nom", "Santé");
        var domaineResult = await domaine.first();

        progressCallback(80);
        UserGroup.set("domaine", domaineResult);
        UserGroup.set("compte_valide", false);
        UserGroup.set("name", user.prenom + user.nom);
        UserGroup.set("essai_gratuit", true);
        var passwordGenerateTherapeute = codeGenerator("xxxxxxxx");
        UserGroup.set("password_cabinet", passwordGenerateTherapeute);
        var date15j = new Date();
        if (!isStagiaire) {
          date15j.setDate(date15j.getDate() + 15);
        } else {
          date15j.setDate(date15j.getDate() + 30);
        }
        console.log("Date:", date15j, " isStagiaire:", isStagiaire);
        UserGroup.set("subscription_end_date", date15j);
        UserGroup.set("credits", 60);
        UserGroup.set("type_abonnement", "pulse");
        progressCallback(90);
        var userGroupSave = await UserGroup.save();

        let progress = 90;

        NewUser.set("group", userGroupSave);
        // Augmentez de 1% chaque seconde jusqu'à 99%
        let intervalId = setInterval(() => {
          if (progress < 100) {
            progress++;
            progressCallback(progress);

            // Arrêtez d'augmenter lorsque vous atteignez 99%
            if (progress >= 99) {
              clearInterval(intervalId);
            }
          }
        }, 1000);

        NewUser.save()
          .then((NewUser) => {
            progressCallback(100);
            //console.log("Utilisateur créé avec succès", NewUser);
            resolve(NewUser);
          })
          .catch((error) => {
            console.error("Erreur lors de la création de l'utilisateur", error);
            reject(error);
          });
      } catch (error) {
        //console.log("Une erreur est survenu", error);
      }
    });
  },
};

export default UserService;
