import Parse from "parse";

export default function ParseConfig() {
  //ANCIENNE DEV

  // Parse.initialize(
  //  "ilYgUXld1YqqqMvfpqRk59OqXt6MAKd8jis9oLhg",
  //  "bylx336ZKZ4fQYnClEQ4TrTxMmkn1BhWYK07fgkP"
  // );
  // Parse.serverURL = "https://www.dev.symbiocenter.com/parse";

  // NEW DEV

  Parse.initialize("dev_symbiocenter", "ClEQ4TrTxMmkn1BhWYK0");

  Parse.serverURL = "https://46.105.79.117:1337/parse";
}
