import Vue from "vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
export async function setGlobalVariables() {
  try {
    ParseConfig();
    var user = Parse.User.current();
    console.log("%cUtilisateur récupéré ", "color: blue");
    console.log(user);

    if (user == null) {
      return;
    }
    if (user.attributes.new_version_enabled) {
      var NewVersionEnabled = user.attributes.new_version_enabled;
    } else {
      var NewVersionEnabled = false;
    }

    Vue.prototype.$NewVersionEnabled = NewVersionEnabled;
    if (user.attributes.therapeute) {
      var Therapeute = user.attributes.therapeute;
    } else {
      var Therapeute = false;
    }
    Vue.prototype.$Therapeute = Therapeute;
    if (user.attributes.group.attributes.essai_gratuit) {
      var EssaiGratuit = user.attributes.group.attributes.essai_gratuit;
    } else {
      var EssaiGratuit = false;
    }
    Vue.prototype.$EssaiGratuit = EssaiGratuit;

    var IsTherapeuteWithoutIdPatient = Therapeute && !Vue.prototype.$IdPatient;
    console.log("IsTherapeuteWithoutIdPatient globalSettings", IsTherapeuteWithoutIdPatient);
    Vue.prototype.$IsTherapeuteWithoutIdPatient = IsTherapeuteWithoutIdPatient;

  } catch (error) {
    console.log(error);
  }
}
export async function setIdPatientGlobal(id) {
  try {
    console.log('SetIdPatientGlobal',id)
    Vue.prototype.$IdPatient = id;
    Vue.prototype.$IsTherapeuteWithoutIdPatient = false;

  } catch (error) {
    console.log(error);
  }
}
export async function resetIdPatientGlobal() {
  try {
    Vue.prototype.$IdPatient = null;
    Vue.prototype.$IsTherapeuteWithoutIdPatient = true;

  } catch (error) {
    console.log(error);
  }
}
export async function setLinksGlobal(links) {
  try {
    Vue.prototype.$LinksGlobal = links;
    console.log("SetLinksGlobal", links);
  } catch (error) {
    console.log(error);
  }
}
export async function resetLinksGlobal() {
  try {
    Vue.prototype.$LinksGlobal = null;
  } catch (error) {
    console.log(error);
  }
}

export async function setEssaiGratuit(id) {
  try {
    Vue.prototype.$EssaiGratuit = id;
  } catch (error) {
    console.log(error);
  }
}

